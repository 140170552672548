import React from "react";
import "./header.scss";
export default function BannerView(props) {
    return (
        <header className="header">
            <div className="container header-banner" id="banner">
                <div className="row">
                    <div className="col-md-6">
                        <div className="logo d-flex">
                            <a href="/">
                                <img
                                    className="logo"
                                    src="/logo.png"
                                    alt="Logo"
                                    height="100"
                                />
                            </a>
                            <div id="header-title">
                                <div className="title1">CHI CỤC THỦY LỢI</div>
                                <div className="title2">SỞ NÔNG NGHIỆP VÀ PHÁT TRIỂN NÔNG THÔN</div>
                                <div className="title3">HẢI DƯƠNG</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
